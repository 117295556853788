import React from 'react'
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from "react-slick";
import config from 'src/config/config';
import { theme } from 'styled-tools';
import PlaceholderImage from 'src/components/Placeholder';
import { withAppContext } from 'src/store/initAppContext';


// Sponsor Slider Images
const imageSets = {
    YOLO: [
        { "id": "1", "image": "static/cashsite/brand/6/image/yoloSponsorSilderOne.png" },
        { "id": "2", "image": "static/cashsite/brand/6/image/yoloSponsorSilderTwo.png" },
    ],
    FOMO: [
        { "id": "1", "image": "static/cashsite/brand/6/image/fomoSponsorSilderOne2025.png" },
        { "id": "2", "image": "static/cashsite/brand/6/image/fomoSponsorSilderTwo2025.png" },
    ],
};

const getSponsorImages = (brandId: any) => {
    if ([31, 32].includes(brandId)) return imageSets.YOLO;
    if ([39, 40].includes(brandId)) return imageSets.FOMO;
    return [];
};

const FomoSponsorsSlider = (props: any) => {

    const { app: { brandId }, theme } = props

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        pauseOnHover: true
    };

    const sponsorImages = getSponsorImages(brandId);

    return (
        <ImageBannerWrapper>
            {sponsorImages && sponsorImages.length > 0 ? (
                <Slider {...settings}>
                    {sponsorImages.map((item: any) => {
                        return (
                            <div key={item.id}>
                                <LazyLoadImage
                                    src={`${config.imageV3Host}/${item.image}`}
                                    alt="Sponsors"
                                    effect="none"
                                    height="100%"
                                    width="100%"
                                    placeholder={<PlaceholderImage />}
                                />
                            </div>
                        );
                    })}
                </Slider>
            ) : null}
        </ImageBannerWrapper>
    )
}

export default withTheme(withAppContext(FomoSponsorsSlider));

const ImageBannerWrapper = styled.div`
    width: 100%;
    position: relative;
    padding: 1rem;
    margin-bottom: ${theme('palette.AuthSponsorsSlider')};

    img {
        width: ${theme('palette.AuthSponsorsSliderImgWidth')};
        height: ${theme('palette.AuthSponsorsSliderImgHeight')};
    }

    .slick-dots {
        display: flex!important;
        align-items: center;
        justify-content: center;
        padding: 10px;
        bottom: -2rem;

        li {
            margin: 0px 1px;
        }
        li button:before {
            font-size: 13px;
            color: ${theme('palette.BannerDotsColor')};
        }
        li.slick-active button:before {
            opacity: .75;
            color: ${theme('palette.BannerDotsActiveColor')};
        }
    }
`;