export const submenus = {
    31:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/live-roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/live-casino/live-blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/live-casino/live-baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/popular-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {

                    id: 'rm1',
                    url:'/live-casino',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/slots',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
             
                    ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinko-x',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/football-x',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninja-crash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                  
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    }
                
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/aviatrix',
                    image: 'AviatriXdropdown.png',
                    label: 'GAME@AVIATRIX',
                    authkey: "showInstantThumbs"
                }
                
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/monopoly-live',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/live-casino/funky-time',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/live-casino/lightning-dice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricket-x',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [

                {
                    id: 'rm1',
                    url:'/instant-games/mines',
                    image: 'spribe-minesdropdown.png',
                    label: 'TITLE@Mines',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/live-casino/crazy-time',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/live-casino/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                }
               
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    // {
                    //     id: '4',
                    //     url: '/betting-exchange/volleyball-betting',
                    //     icon: '',
                    //     label: 'SPORT@VolleyBall',
                    //     authkey: 'showSportVolleyball'
                    // },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    },
                    {
                        id: '6',
                        url: '/sports/tournaments/',
                        icon: '',
                        label: 'TITLE@Tournaments',
                        authkey: 'showTournaments'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'DROPDOWN_EXCHANGE_NEW.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownYolo1.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@REFILL',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '15',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo"
                    },
                    {
                        id: '12',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '13',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '14',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    {
                        id: '15',
                        url: '/promotions/catch99-s2',
                        icon: '',
                        authkey:"showCatch99Bonus",
                        label: "PROMOTIONS@CATCH99"
                    },
                    // {
                    //     id: '16',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '17',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '18',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '19',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '20',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '21',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    }, 
                    {
                        id: '22',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartsoft",
                        label: "PROMOTIONS@Smartsoft"
                    },


                    {
                        id: '23',
                        url: '/promotions/evolution-casino-cup',
                        icon: '',
                        authkey:"showEvolutionCasionCup",
                        label: "PROMOTIONS@EvolutionCasionCup"
                    },




                    {
                        id: '24',
                        url: '/promotions/IPL-Betting-dhamaka-Power-Play-Bonus',
                        icon: '',
                        authkey:"shoeIPLpowerPlay",
                        label: "PROMOTIONS@IPLPOWERPLAY1"
                    },



                    {
                        id: '25',
                        url: '/promotions/indian-galaxsys-league',
                        icon: '',
                        authkey:"showIndianGalaxysLeague",
                        label: "PROMOTIONS@IndianGalaxsysLeague"
                    },
                   
                    



                        {
                        id: '26',
                        url: '/promotions/ipl-betting-dhamaka',
                        icon: '',
                        authkey:"showIplPromo",
                        label: "PROMOTIONS@iplmatka"},



                        {
                            id: '27',
                            url: '/promotions/double-cashback',
                            icon: '',
                            authkey:"showDoubleCashBack",
                            label: "PROMOTIONS@DoubleCashback"
                        },


                        {
                        id: '28',
                        url: '/promotions/aviator-sky-bound-miles',
                        icon: '',
                        authkey:"showAviatorSkyBoundMiles",
                        label: "PROMOTIONS@AviatorSkyBoundMiles"
                    },
                    {
                        id: '29',
                        url: '/promotions/wc-lossback',
                        icon: '',
                        authkey:"showWorldCupLossbackYolo",
                        label: "PROMOTIONS@WorldCupLossbackBonus"
                    },
                    {
                        id: '30',
                        url: '/promotions/wc-doubleCashback',
                        icon: '',
                        authkey:"showWorldCupDoubleCashbackBonus",
                        label: "PROMOTIONS@WorldCupDailyDepositBonus"
                    },
                    {
                        id: '31',
                        url: '/promotions/wc-evolutionCasinoCup',
                        icon: '',
                        authkey:"showWorldCupEvolutionCasinoCup",
                        label: "PROMOTIONS@WorldCupEvolutionCasinoCupBonus"
                    },
                    {
                        id: '34',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartSoftJetxCelestial",
                        label: "PROMOTIONS@SmartSoftJetxCelestial"
                    },
                    {
                        id: '35',
                        url: '/promotions/mines-legend-trophy',
                        icon: '',
                        authkey:"showMinesLegendTrophyPromo",
                        label: "PROMOTIONS@MinesLegendTrophy"
                    },
                    {
                        id: '36',
                        url: '/promotions/caribbean-premier-league',
                        icon: '',
                        authkey:"showCaribbeanLeagueBonusPromo",
                        label: "PROMOTIONS@CaribbeanLeagueBonus"
                    },
                    {
                        id: '37',
                        url: '/promotions/evolution-roulette-cup',
                        icon: '',
                        authkey:"showEvolutionRouletteCupPromo",
                        label: "PROMOTIONS@EvolutionRouletteCup"
                    },
                    {
                        id: '38',
                        url: '/promotions/aviatrix-fly-and-play',
                        icon: '',
                        authkey:"showAviatrixFlyAndPlayPromo",
                        label: "PROMOTIONS@AviatrixFlyAndPlay"
                    },
                    {
                        id: '39',
                        url: '/promotions/aviatrix-jet-to-cash',
                        icon: '',
                        authkey:"showAviatrixJetToCrash",
                        label: "PROMOTIONS@AviatrixJetToCrash"
                    },
                    {
                        id: '40',
                        url: '/promotions/aviator-rain',
                        icon: '',
                        authkey:"showAviatorRainPromo",
                        label: "PROMOTIONS@AviatorRainPromo"
                    },
                    {
                        id: '41',
                        url: '/promotions/jet-set-with-jetx',
                        icon: '',
                        authkey:"showJetSetWithJetXPromo",
                        label: "PROMOTIONS@JetSetWithJetX"
                    },
                    {
                        id: '42',
                        url: '/promotions/womens-world-cup',
                        icon: '',
                        authkey:"showWomensWorldCupPromo",
                        label: "PROMOTIONS@WomensWorldCup"
                    },
                    {
                        id: '43',
                        url: '/promotions/big-bash-big-cash',
                        icon: '',
                        authkey:"showBigBashBigCashPromo",
                        label: "PROMOTIONS@BigBashBigCash"
                    },
                    {
                        id: '44',
                        url: '/promotions/the-live-jackpot',
                        icon: '',
                        authkey:"showLiveJackPot2025Promo",
                        label: "PROMOTIONS@LiveJackPot2025"
                    },
                    {
                        id: '45',
                        url: '/promotions/instant-challenge-2025',
                        icon: '',
                        authkey:"showInstantChallengePromo",
                        label: "PROMOTIONS@InstantChallengePromo"
                    },
                    {
                        id: '46',
                        url: '/promotions/super-slots-challenge',
                        icon: '',
                        authkey:"showSuperSlotsChallengePromo",
                        label: "PROMOTIONS@SuperSlotsChallenge"
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@REFILL',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@CASHBACK',
                    authkey: "showCashbackBonus"
                }
            ]
        },
    },
    32:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/live-roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/live-casino/live-blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/live-casino/live-baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/popular-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {
                    id: 'rm1',
                    url:'/live-casino',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/slots',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
            ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinko-x',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/football-x',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninja-crash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                    
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    }
                
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/aviatrix',
                    image: 'AviatriXdropdown.png',
                    label: 'GAME@AVIATRIX',
                    authkey: "showInstantThumbs"
                }
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/monopoly-live',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/live-casino/funky-time',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/live-casino/lightning-dice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricket-x',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [
             
                {
                    id: 'rm1',
                    url:'/instant-games/mines',
                    image: 'spribe-minesdropdown.png',
                    label: 'TITLE@Mines',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/live-casino/crazy-time',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/live-casino/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                }
               
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    // {
                    //     id: '4',
                    //     url: '/betting-exchange/volleyball-betting',
                    //     icon: '',
                    //     label: 'SPORT@VolleyBall',
                    //     authkey: 'showSportVolleyball'
                    // },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    },
                    {
                        id: '6',
                        url: '/sports/tournaments/',
                        icon: '',
                        label: 'TITLE@Tournaments',
                        authkey: 'showTournaments'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'DROPDOWN_EXCHANGE_NEW.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownYolo1.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@REFILL',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '15',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo"
                    },
                    
                    {
                        id: '12',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '13',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '14',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    {
                        id: '15',
                        url: '/promotions/catch99-s2',
                        icon: '',
                        authkey:"showCatch99Bonus",
                        label: "PROMOTIONS@CATCH99"
                    },
                    // {
                    //     id: '16',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '17',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '18',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '19',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '20',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '21',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    },
                    {
                        id: '22',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartsoft",
                        label: "PROMOTIONS@Smartsoft"
                    },



                    {
                        id: '23',
                        url: '/promotions/evolution-casino-cup',
                        icon: '',
                        authkey:"showEvolutionCasionCup",
                        label: "PROMOTIONS@EvolutionCasionCup"
                    },




                    {
                        id: '24',
                        url: '/promotions/IPL-Betting-dhamaka-Power-Play-Bonus',
                        icon: '',
                        authkey:"shoeIPLpowerPlay",
                        label: "PROMOTIONS@IPLPOWERPLAY1"
                    },



                    {
                        id: '25',
                        url: '/promotions/indian-galaxsys-league',
                        icon: '',
                        authkey:"showIndianGalaxysLeague",
                        label: "PROMOTIONS@IndianGalaxsysLeague"
                    },
                   
                    

                        {
                        id: '26',
                        url: '/promotions/ipl-betting-dhamaka',
                        icon: '',
                        authkey:"showIplPromo",
                        label: "PROMOTIONS@iplmatka"},



                        {
                            id: '27',
                            url: '/promotions/double-cashback',
                            icon: '',
                            authkey:"showDoubleCashBack",
                            label: "PROMOTIONS@DoubleCashback"
                        },


                        {
                        id: '28',
                        url: '/promotions/aviator-sky-bound-miles',
                        icon: '',
                        authkey:"showAviatorSkyBoundMiles",
                        label: "PROMOTIONS@AviatorSkyBoundMiles"
                    },
                    {
                        id: '29',
                        url: '/promotions/wc-lossback',
                        icon: '',
                        authkey:"showWorldCupLossbackYolo",
                        label: "PROMOTIONS@WorldCupLossbackBonus"
                    },
                    {
                        id: '30',
                        url: '/promotions/wc-doubleCashback',
                        icon: '',
                        authkey:"showWorldCupDoubleCashbackBonus",
                        label: "PROMOTIONS@WorldCupDailyDepositBonus"
                    },
                    {
                        id: '31',
                        url: '/promotions/wc-evolutionCasinoCup',
                        icon: '',
                        authkey:"showWorldCupEvolutionCasinoCup",
                        label: "PROMOTIONS@WorldCupEvolutionCasinoCupBonus"
                    },
                    {
                        id: '34',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartSoftJetxCelestial",
                        label: "PROMOTIONS@SmartSoftJetxCelestial"
                    },
                    {
                        id: '35',
                        url: '/promotions/mines-legend-trophy',
                        icon: '',
                        authkey:"showMinesLegendTrophyPromo",
                        label: "PROMOTIONS@MinesLegendTrophy"
                    },
                    {
                        id: '36',
                        url: '/promotions/caribbean-premier-league',
                        icon: '',
                        authkey:"showCaribbeanLeagueBonusPromo",
                        label: "PROMOTIONS@CaribbeanLeagueBonus"
                    },
                    {
                        id: '37',
                        url: '/promotions/evolution-roulette-cup',
                        icon: '',
                        authkey:"showEvolutionRouletteCupPromo",
                        label: "PROMOTIONS@EvolutionRouletteCup"
                    },
                    {
                        id: '38',
                        url: '/promotions/aviatrix-fly-and-play',
                        icon: '',
                        authkey:"showAviatrixFlyAndPlayPromo",
                        label: "PROMOTIONS@AviatrixFlyAndPlay"
                    },
                    {
                        id: '39',
                        url: '/promotions/aviatrix-jet-to-cash',
                        icon: '',
                        authkey:"showAviatrixJetToCrash",
                        label: "PROMOTIONS@AviatrixJetToCrash"
                    },
                    {
                        id: '40',
                        url: '/promotions/aviator-rain',
                        icon: '',
                        authkey:"showAviatorRainPromo",
                        label: "PROMOTIONS@AviatorRainPromo"
                    },
                    {
                        id: '41',
                        url: '/promotions/jet-set-with-jetx',
                        icon: '',
                        authkey:"showJetSetWithJetXPromo",
                        label: "PROMOTIONS@JetSetWithJetX"
                    },
                    {
                        id: '42',
                        url: '/promotions/womens-world-cup',
                        icon: '',
                        authkey:"showWomensWorldCupPromo",
                        label: "PROMOTIONS@WomensWorldCup"
                    },
                    {
                        id: '43',
                        url: '/promotions/big-bash-big-cash',
                        icon: '',
                        authkey:"showBigBashBigCashPromo",
                        label: "PROMOTIONS@BigBashBigCash"
                    },
                    {
                        id: '44',
                        url: '/promotions/the-live-jackpot',
                        icon: '',
                        authkey:"showLiveJackPot2025Promo",
                        label: "PROMOTIONS@LiveJackPot2025"
                    },
                    {
                        id: '45',
                        url: '/promotions/instant-challenge-2025',
                        icon: '',
                        authkey:"showInstantChallengePromo",
                        label: "PROMOTIONS@InstantChallengePromo"
                    },
                    {
                        id: '46',
                        url: '/promotions/super-slots-challenge',
                        icon: '',
                        authkey:"showSuperSlotsChallengePromo",
                        label: "PROMOTIONS@SuperSlotsChallenge"
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@REFILL',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@CASHBACK',
                    authkey: "showCashbackBonus"
                }
            ]
        },
    },
    33:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/live-roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/live-casino/live-blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/live-casino/live-baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/popular-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {
                    id: 'rm1',
                    url:'/live-casino',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/slots',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
            ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinko-x',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/football-x',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninja-crash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                   
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    },
                
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/aviatrix',
                    image: 'AviatriXdropdown.png',
                    label: 'GAME@AVIATRIX',
                    authkey: "showInstantThumbs"
                }
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/monopoly-live',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/live-casino/funky-time',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/live-casino/lightning-dice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricket-x',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [
                
                {
                    id: 'rm1',
                    url:'/instant-games/mines',
                    image: 'spribe-minesdropdown.png',
                    label: 'TITLE@Mines',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/live-casino/crazy-time',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/live-casino/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                }
               
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    // {
                    //     id: '4',
                    //     url: '/betting-exchange/volleyball-betting',
                    //     icon: '',
                    //     label: 'SPORT@VolleyBall',
                    //     authkey: 'showSportVolleyball'
                    // },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'ExchangeDropdownBA.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownBetdaily.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@REFILL',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '12',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo",
                    },
                    {
                        id: '13',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '14',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '15',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    // {
                    //     id: '15',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '16',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '17',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '18',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '19',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '20',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    },
                    {
                        id: '22',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartsoft",
                        label: "PROMOTIONS@Smartsoft"
                    },

                    {
                        id: '23',
                        url: '/promotions/evolution-casino-cup',
                        icon: '',
                        authkey:"showEvolutionCasionCup",
                        label: "PROMOTIONS@EvolutionCasionCup"
                    },




                    {
                        id: '24',
                        url: '/promotions/IPL-Betting-dhamaka-Power-Play-Bonus',
                        icon: '',
                        authkey:"shoeIPLpowerPlay",
                        label: "PROMOTIONS@IPLPOWERPLAY1"
                    },



                    {
                        id: '25',
                        url: '/promotions/indian-galaxsys-league',
                        icon: '',
                        authkey:"showIndianGalaxysLeague",
                        label: "PROMOTIONS@IndianGalaxsysLeague"
                    },
                   
                    



                        {
                        id: '26',
                        url: '/promotions/ipl-betting-dhamaka',
                        icon: '',
                        authkey:"showIplPromo",
                        label: "PROMOTIONS@iplmatka"},



                        {
                            id: '27',
                            url: '/promotions/double-cashback',
                            icon: '',
                            authkey:"showDoubleCashBack",
                            label: "PROMOTIONS@DoubleCashback"
                        },


                        {
                        id: '28',
                        url: '/promotions/aviatrix-free-bets-extravaganza',
                        icon: '',
                        authkey:"showAviatrixFBEPromo",
                        label: "PROMOTIONS@AviatrixFBE"
                    },
                    {
                        id: '29',
                        url: '/promotions/wc-double-Cashback',
                        icon: '',
                        authkey:"showWorldCupDoubleCashBackBazzi",
                        label: "PROMOTIONS@WorldCupDoubleCashbackBonusBazzi"
                    },
                    {
                        id: '30',
                        url: '/promotions/evolution-casino-tourney',
                        icon: '',
                        authkey:"showEvolutionCasinoTourneyBazzi",
                        label: "PROMOTIONS@EvolutionCasinoTourneyBazzi"
                    },
                    {
                        id: '31',
                        url: '/promotions/daily-rewards',
                        icon: '',
                        authkey:"showDailyRewardsBazzi",
                        label: "PROMOTIONS@DailyRewardsBazzi"
                    },
                    {
                        id: '34',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartSoftJetxCelestial",
                        label: "PROMOTIONS@SmartSoftJetxCelestial"
                    },
                    {
                        id: '35',
                        url: '/promotions/caribbean-premier-league',
                        icon: '',
                        authkey:"showCaribbeanLeagueBonusPromo",
                        label: "PROMOTIONS@CaribbeanLeagueBonus"
                    },
                    {
                        id: '38',
                        url: '/promotions/aviatrix-fly-and-play',
                        icon: '',
                        authkey:"showAviatrixFlyAndPlayPromo",
                        label: "PROMOTIONS@AviatrixFlyAndPlay"
                    },
                    {
                        id: '39',
                        url: '/promotions/aviatrix-jet-to-cash',
                        icon: '',
                        authkey:"showAviatrixJetToCrash",
                        label: "PROMOTIONS@AviatrixJetToCrash"
                    },
                    {
                        id: '40',
                        url: '/promotions/jet-set-with-jetx',
                        icon: '',
                        authkey:"showJetSetWithJetXPromo",
                        label: "PROMOTIONS@JetSetWithJetX"
                    },
                    {
                        id: '41',
                        url: '/promotions/womens-world-cup',
                        icon: '',
                        authkey:"showWomensWorldCupPromo",
                        label: "PROMOTIONS@WomensWorldCup"
                    },
                    {
                        id: '42',
                        url: '/promotions/big-bash-big-cash',
                        icon: '',
                        authkey:"showBigBashBigCashPromo",
                        label: "PROMOTIONS@BigBashBigCash"
                    },
                    {
                        id: '43',
                        url: '/promotions/the-live-jackpot',
                        icon: '',
                        authkey:"showLiveJackPot2025Promo",
                        label: "PROMOTIONS@LiveJackPot2025"
                    },
                    {
                        id: '44',
                        url: '/promotions/instant-challenge-2025',
                        icon: '',
                        authkey:"showInstantChallengePromo",
                        label: "PROMOTIONS@InstantChallengePromo"
                    },
                   
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@REFILL',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@CASHBACK',
                    authkey: "showCashbackBonus"
                }
            ]
        },
    },
    34:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/live-roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/live-casino/live-blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/live-casino/live-baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/popular-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {
                    id: 'rm1',
                    url:'/live-casino',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/slots',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
            ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinko-x',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/football-x',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninja-crash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                   
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    },
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/aviatrix',
                    image: 'AviatriXdropdown.png',
                    label: 'GAME@AVIATRIX',
                    authkey: "showInstantThumbs"
                }
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/monopoly-live',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/live-casino/funky-time',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/live-casino/lightning-dice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricket-x',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [
                
                {
                    id: 'rm1',
                    url:'/instant-games/mines',
                    image: 'spribe-minesdropdown.png',
                    label: 'TITLE@Mines',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/live-casino/crazy-time',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/live-casino/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                }
               
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    // {
                    //     id: '4',
                    //     url: '/betting-exchange/volleyball-betting',
                    //     icon: '',
                    //     label: 'SPORT@VolleyBall',
                    //     authkey: 'showSportVolleyball'
                    // },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'ExchangeDropdownBA.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownBetdaily.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@REFILL',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '12',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo"
                    },
                    {
                        id: '13',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '14',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '15',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    // {
                    //     id: '15',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '16',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '17',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '18',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '19',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '20',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    },
                    {
                        id: '22',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartsoft",
                        label: "PROMOTIONS@Smartsoft"
                    }     ,
                    
                    {
                        id: '23',
                        url: '/promotions/evolution-casino-cup',
                        icon: '',
                        authkey:"showEvolutionCasionCup",
                        label: "PROMOTIONS@EvolutionCasionCup"
                    },




                    {
                        id: '24',
                        url: '/promotions/IPL-Betting-dhamaka-Power-Play-Bonus',
                        icon: '',
                        authkey:"shoeIPLpowerPlay",
                        label: "PROMOTIONS@IPLPOWERPLAY1"
                    },



                    {
                        id: '25',
                        url: '/promotions/indian-galaxsys-league',
                        icon: '',
                        authkey:"showIndianGalaxysLeague",
                        label: "PROMOTIONS@IndianGalaxsysLeague"
                    },
                   
                    



                        {
                        id: '26',
                        url: '/promotions/ipl-betting-dhamaka',
                        icon: '',
                        authkey:"showIplPromo",
                        label: "PROMOTIONS@iplmatka"},



                        {
                            id: '27',
                            url: '/promotions/double-cashback',
                            icon: '',
                            authkey:"showDoubleCashBack",
                            label: "PROMOTIONS@DoubleCashback"
                        },


                        {
                        id: '28',
                        url: '/promotions/aviator-sky-bound-miles',
                        icon: '',
                        authkey:"showAviatorSkyBoundMiles",
                        label: "PROMOTIONS@AviatorSkyBoundMiles"
                    },
                    {
                        id: '29',
                        url: '/promotions/wc-double-Cashback',
                        icon: '',
                        authkey:"showWorldCupDoubleCashBackBazzi",
                        label: "PROMOTIONS@WorldCupDoubleCashbackBonusBazzi"
                    } ,
                    {
                        id: '30',
                        url: '/promotions/evolution-casino-tourney',
                        icon: '',
                        authkey:"showEvolutionCasinoTourneyBazzi",
                        label: "PROMOTIONS@EvolutionCasinoTourneyBazzi"
                    },
                    {
                        id: '31',
                        url: '/promotions/daily-rewards',
                        icon: '',
                        authkey:"showDailyRewardsBazzi",
                        label: "PROMOTIONS@DailyRewardsBazzi"
                    },
                    {
                        id: '34',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartSoftJetxCelestial",
                        label: "PROMOTIONS@SmartSoftJetxCelestial"
                    },
                    {
                        id: '35',
                        url: '/promotions/caribbean-premier-league',
                        icon: '',
                        authkey:"showCaribbeanLeagueBonusPromo",
                        label: "PROMOTIONS@CaribbeanLeagueBonus"
                    },
                    {
                        id: '38',
                        url: '/promotions/aviatrix-fly-and-play',
                        icon: '',
                        authkey:"showAviatrixFlyAndPlayPromo",
                        label: "PROMOTIONS@AviatrixFlyAndPlay"
                    },
                    {
                        id: '39',
                        url: '/promotions/aviatrix-jet-to-cash',
                        icon: '',
                        authkey:"showAviatrixJetToCrash",
                        label: "PROMOTIONS@AviatrixJetToCrash"
                    },
                    {
                        id: '40',
                        url: '/promotions/jet-set-with-jetx',
                        icon: '',
                        authkey:"showJetSetWithJetXPromo",
                        label: "PROMOTIONS@JetSetWithJetX"
                    },
                    {
                        id: '41',
                        url: '/promotions/womens-world-cup',
                        icon: '',
                        authkey:"showWomensWorldCupPromo",
                        label: "PROMOTIONS@WomensWorldCup"
                    },
                    {
                        id: '43',
                        url: '/promotions/big-bash-big-cash',
                        icon: '',
                        authkey:"showBigBashBigCashPromo",
                        label: "PROMOTIONS@BigBashBigCash"
                    },
                    {
                        id: '44',
                        url: '/promotions/the-live-jackpot',
                        icon: '',
                        authkey:"showLiveJackPot2025Promo",
                        label: "PROMOTIONS@LiveJackPot2025"
                    },
                    {
                        id: '45',
                        url: '/promotions/instant-challenge-2025',
                        icon: '',
                        authkey:"showInstantChallengePromo",
                        label: "PROMOTIONS@InstantChallengePromo"
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@REFILL',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@CASHBACK',
                    authkey: "showCashbackBonus"
                }
            ]
        },
    },
    39:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/live-roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/live-casino/live-blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/live-casino/live-baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/popular-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {

                    id: 'rm1',
                    url:'/live-casino',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/slots',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
             
                    ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinko-x',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/football-x',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninja-crash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                  
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    }
                
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/aviatrix',
                    image: 'AviatriXdropdown.png',
                    label: 'GAME@AVIATRIX',
                    authkey: "showInstantThumbs"
                }
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/monopoly-live',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/live-casino/funky-time',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/live-casino/lightning-dice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricket-x',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [
               
                {
                    id: 'rm1',
                    url:'/instant-games/mines',
                    image: 'spribe-minesdropdown.png',
                    label: 'TITLE@Mines',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/live-casino/crazy-time',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/live-casino/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                }
               
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    // {
                    //     id: '4',
                    //     url: '/betting-exchange/volleyball-betting',
                    //     icon: '',
                    //     label: 'SPORT@VolleyBall',
                    //     authkey: 'showSportVolleyball'
                    // },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    },
                    {
                        id: '6',
                        url: '/sports/tournaments/ ',
                        icon: '',
                        label: 'TITLE@Tournaments',
                        authkey: 'showTournaments'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'DROPDOWN_EXCHANGE_FOMO.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownFomo.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONS@REFILLBONUS',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '15',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo"
                    },
                    {
                        id: '12',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '13',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '14',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    {
                        id: '15',
                        url: '/promotions/catch99-s2',
                        icon: '',
                        authkey:"showCatch99Bonus",
                        label: "PROMOTIONS@CATCH99"
                    },
                    // {
                    //     id: '16',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '17',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '18',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '19',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '20',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '21',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    }, 
                    {
                        id: '22',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartsoft",
                        label: "PROMOTIONS@Smartsoft"
                    },


                    {
                        id: '23',
                        url: '/promotions/evolution-casino-cup',
                        icon: '',
                        authkey:"showEvolutionCasionCup",
                        label: "PROMOTIONS@EvolutionCasionCup"
                    },
                    {
                        id: '24',
                        url: '/promotions/IPL-Betting-dhamaka-Power-Play-Bonus',
                        icon: '',
                        authkey:"shoeIPLpowerPlay",
                        label: "PROMOTIONS@IPLPOWERPLAY1"
                    },
                    {
                        id: '25',
                        url: '/promotions/indian-galaxsys-league',
                        icon: '',
                        authkey:"showIndianGalaxysLeague",
                        label: "PROMOTIONS@IndianGalaxsysLeague"
                    },
                    {
                        id: '26',
                        url: '/promotions/ipl-betting-dhamaka',
                        icon: '',
                        authkey:"showIplPromo",
                        label: "PROMOTIONS@iplmatka"
                    },
                    {
                        id: '27',
                        url: '/promotions/double-cashback',
                        icon: '',
                        authkey:"showDoubleCashBack",
                        label: "PROMOTIONS@DoubleCashback"
                    },
                    {
                        id: '28',
                        url: '/promotions/aviator-sky-bound-miles',
                        icon: '',
                        authkey:"showAviatorSkyBoundMiles",
                        label: "PROMOTIONS@AviatorSkyBoundMiles"
                    },
                    {
                        id: '29',
                        url: '/promotions/wc-LossBack-Exchange',
                        icon: '',
                        authkey:"showFomoWcLossBackExchPromo",
                        label: "PROMOTIONS@FomoWorldCupLossBackExch"
                    },
                    {
                        id: '30',
                        url: '/promotions/wc-goIndia-goJackpot',
                        icon: '',
                        authkey:"showFomoGoIndiaGoJackpotPromo",
                        label: "PROMOTIONS@FomoWorldCupGoIndiaGoJackpot"
                    },
                    {
                        id: '30',
                        url: '/promotions/aviator-wingsOfAdventure',
                        icon: '',
                        authkey:"showFomoAviatorWingsofAdventurePromo",
                        label: "PROMOTIONS@FomoAviatorWingsOfAdventure"
                    },
                    {
                        id: '31',
                        url: '/promotions/evolution-casino-challenge',
                        icon: '',
                        authkey:"showFomoEvolutionCasinoChallengePromo",
                        label: "PROMOTIONS@FomoEvolutionCasinoChallenge"
                    },
                    {
                        id: '32',
                        url: '/promotions/aviatrix-fiyHigh',
                        icon: '',
                        authkey:"showFomoAviatrixFlyHighPromo",
                        label: "PROMOTIONS@FomoAviatrixFlyHigh"
                    },
                    {
                        id: '33',
                        url: '/promotions/wc-dailyDeposit-bonus',
                        icon: '',
                        authkey:"showWcDailyDepositBonusPromo",
                        label: "PROMOTIONS@FomoWorldCupDailyDesposit"
                    },
                    {
                        id: '34',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartSoftJetxCelestial",
                        label: "PROMOTIONS@SmartSoftJetxCelestial"
                    },
                    {
                        id: '35',
                        url: '/promotions/mines-legend-trophy',
                        icon: '',
                        authkey:"showMinesLegendTrophyPromo",
                        label: "PROMOTIONS@MinesLegendTrophy"
                    },
                    {
                        id: '36',
                        url: '/promotions/caribbean-premier-league',
                        icon: '',
                        authkey:"showCaribbeanLeagueBonusPromo",
                        label: "PROMOTIONS@CaribbeanLeagueBonus"
                    },
                    {
                        id: '37',
                        url: '/promotions/evolution-roulette-cup',
                        icon: '',
                        authkey:"showEvolutionRouletteCupPromo",
                        label: "PROMOTIONS@EvolutionRouletteCup"
                    },
                    {
                        id: '38',
                        url: '/promotions/aviatrix-fly-and-play',
                        icon: '',
                        authkey:"showAviatrixFlyAndPlayPromo",
                        label: "PROMOTIONS@AviatrixFlyAndPlay"
                    },
                    {
                        id: '39',
                        url: '/promotions/aviatrix-jet-to-cash',
                        icon: '',
                        authkey:"showAviatrixJetToCrash",
                        label: "PROMOTIONS@AviatrixJetToCrash"
                    },
                    {
                        id: '40',
                        url: '/promotions/aviator-rain',
                        icon: '',
                        authkey:"showAviatorRainPromo",
                        label: "PROMOTIONS@AviatorRainPromo"
                    },
                    {
                        id: '41',
                        url: '/promotions/jet-set-with-jetx',
                        icon: '',
                        authkey:"showJetSetWithJetXPromo",
                        label: "PROMOTIONS@JetSetWithJetX"
                    },
                    {
                        id: '42',
                        url: '/promotions/womens-world-cup',
                        icon: '',
                        authkey:"showWomensWorldCupPromo",
                        label: "PROMOTIONS@WomensWorldCup"
                    },
                    {
                        id: '43',
                        url: '/promotions/big-bash-big-cash',
                        icon: '',
                        authkey:"showBigBashBigCashPromo",
                        label: "PROMOTIONS@BigBashBigCash"
                    },
                    {
                        id: '44',
                        url: '/promotions/the-live-jackpot',
                        icon: '',
                        authkey:"showLiveJackPot2025Promo",
                        label: "PROMOTIONS@LiveJackPot2025"
                    },
                    {
                        id: '45',
                        url: '/promotions/instant-challenge-2025',
                        icon: '',
                        authkey:"showInstantChallengePromo",
                        label: "PROMOTIONS@InstantChallengePromo"
                    },
                    {
                        id: '46',
                        url: '/promotions/super-slots-challenge',
                        icon: '',
                        authkey:"showSuperSlotsChallengePromo",
                        label: "PROMOTIONS@SuperSlotsChallenge"
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONS@REFILLBONUS',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONS@FomoLossBackBonus',
                    authkey: "showFomoLossBackBonus"
                }
            ]
        },
    },
    40:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/live-roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/live-casino/live-blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/live-casino/live-baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/popular-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {

                    id: 'rm1',
                    url:'/live-casino',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/slots',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
             
                    ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinko-x',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/football-x',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninja-crash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                    {
                        id: '6',
                        url: '/instant-games/aviatrix',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "GAME@AVIATRIX"
                    },
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    }
                
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/rocketon',
                    image: 'DROPDOWN_ROKETON_INSTANT.png',
                    label: 'TITLE@roketon',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                }
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/monopoly-live',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/live-casino/funky-time',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/live-casino/lightning-dice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricket-x',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/live-casino/crazy-time',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/live-casino/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/live-casino/dragon-tiger',
                    image: 'DROPDOWN_evodragontiger.png',
                    label: 'TITLE@DragonTiger',
                    authkey: 'showTeenpattiLink'
                }
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    // {
                    //     id: '4',
                    //     url: '/betting-exchange/volleyball-betting',
                    //     icon: '',
                    //     label: 'SPORT@VolleyBall',
                    //     authkey: 'showSportVolleyball'
                    // },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    },
                    {
                        id: '6',
                        url: '/sports/tournaments/',
                        icon: '',
                        label: 'TITLE@Tournaments',
                        authkey: 'showTournaments'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'DROPDOWN_EXCHANGE_FOMO.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownFomo.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONS@REFILLBONUS',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '15',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo"
                    },
                    {
                        id: '12',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '13',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '14',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    {
                        id: '15',
                        url: '/promotions/catch99-s2',
                        icon: '',
                        authkey:"showCatch99Bonus",
                        label: "PROMOTIONS@CATCH99"
                    },
                    // {
                    //     id: '16',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '17',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '18',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '19',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '20',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '21',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    }, 
                    {
                        id: '22',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartsoft",
                        label: "PROMOTIONS@Smartsoft"
                    },


                    {
                        id: '23',
                        url: '/promotions/evolution-casino-cup',
                        icon: '',
                        authkey:"showEvolutionCasionCup",
                        label: "PROMOTIONS@EvolutionCasionCup"
                    },
                    {
                        id: '24',
                        url: '/promotions/IPL-Betting-dhamaka-Power-Play-Bonus',
                        icon: '',
                        authkey:"shoeIPLpowerPlay",
                        label: "PROMOTIONS@IPLPOWERPLAY1"
                    },
                    {
                        id: '25',
                        url: '/promotions/indian-galaxsys-league',
                        icon: '',
                        authkey:"showIndianGalaxysLeague",
                        label: "PROMOTIONS@IndianGalaxsysLeague"
                    },
                    {
                        id: '26',
                        url: '/promotions/ipl-betting-dhamaka',
                        icon: '',
                        authkey:"showIplPromo",
                        label: "PROMOTIONS@iplmatka"
                    },
                    {
                        id: '27',
                        url: '/promotions/double-cashback',
                        icon: '',
                        authkey:"showDoubleCashBack",
                        label: "PROMOTIONS@DoubleCashback"
                    },
                    {
                        id: '28',
                        url: '/promotions/aviator-sky-bound-miles',
                        icon: '',
                        authkey:"showAviatorSkyBoundMiles",
                        label: "PROMOTIONS@AviatorSkyBoundMiles"
                    },
                    {
                        id: '29',
                        url: '/promotions/wc-LossBack-Exchange',
                        icon: '',
                        authkey:"showFomoWcLossBackExchPromo",
                        label: "PROMOTIONS@FomoWorldCupLossBackExch"
                    },
                    {
                        id: '30',
                        url: '/promotions/wc-goIndia-goJackpot',
                        icon: '',
                        authkey:"showFomoGoIndiaGoJackpotPromo",
                        label: "PROMOTIONS@FomoWorldCupGoIndiaGoJackpot"
                    },
                    {
                        id: '30',
                        url: '/promotions/aviator-wingsOfAdventure',
                        icon: '',
                        authkey:"showFomoAviatorWingsofAdventurePromo",
                        label: "PROMOTIONS@FomoAviatorWingsOfAdventure"
                    },
                    {
                        id: '31',
                        url: '/promotions/evolution-casino-challenge',
                        icon: '',
                        authkey:"showFomoEvolutionCasinoChallengePromo",
                        label: "PROMOTIONS@FomoEvolutionCasinoChallenge"
                    },
                    {
                        id: '32',
                        url: '/promotions/aviatrix-fiyHigh',
                        icon: '',
                        authkey:"showFomoAviatrixFlyHighPromo",
                        label: "PROMOTIONS@FomoAviatrixFlyHigh"
                    },
                    {
                        id: '33',
                        url: '/promotions/wc-dailyDeposit-bonus',
                        icon: '',
                        authkey:"showWcDailyDepositBonusPromo",
                        label: "PROMOTIONS@FomoWorldCupDailyDesposit"
                    },
                    {
                        id: '34',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartSoftJetxCelestial",
                        label: "PROMOTIONS@SmartSoftJetxCelestial"
                    },
                    {
                        id: '35',
                        url: '/promotions/mines-legend-trophy',
                        icon: '',
                        authkey:"showMinesLegendTrophyPromo",
                        label: "PROMOTIONS@MinesLegendTrophy"
                    },
                    {
                        id: '36',
                        url: '/promotions/caribbean-premier-league',
                        icon: '',
                        authkey:"showCaribbeanLeagueBonusPromo",
                        label: "PROMOTIONS@CaribbeanLeagueBonus"
                    },
                    {
                        id: '37',
                        url: '/promotions/evolution-roulette-cup',
                        icon: '',
                        authkey:"showEvolutionRouletteCupPromo",
                        label: "PROMOTIONS@EvolutionRouletteCup"
                    },
                    {
                        id: '38',
                        url: '/promotions/aviatrix-fly-and-play',
                        icon: '',
                        authkey:"showAviatrixFlyAndPlayPromo",
                        label: "PROMOTIONS@AviatrixFlyAndPlay"
                    },
                    {
                        id: '39',
                        url: '/promotions/aviatrix-jet-to-cash',
                        icon: '',
                        authkey:"showAviatrixJetToCrash",
                        label: "PROMOTIONS@AviatrixJetToCrash"
                    },
                    {
                        id: '40',
                        url: '/promotions/aviator-rain',
                        icon: '',
                        authkey:"showAviatorRainPromo",
                        label: "PROMOTIONS@AviatorRainPromo"
                    },
                    {
                        id: '41',
                        url: '/promotions/jet-set-with-jetx',
                        icon: '',
                        authkey:"showJetSetWithJetXPromo",
                        label: "PROMOTIONS@JetSetWithJetX"
                    },
                    {
                        id: '42',
                        url: '/promotions/womens-world-cup',
                        icon: '',
                        authkey:"showWomensWorldCupPromo",
                        label: "PROMOTIONS@WomensWorldCup"
                    },
                    {
                        id: '43',
                        url: '/promotions/big-bash-big-cash',
                        icon: '',
                        authkey:"showBigBashBigCashPromo",
                        label: "PROMOTIONS@BigBashBigCash"
                    },
                    {
                        id: '44',
                        url: '/promotions/the-live-jackpot',
                        icon: '',
                        authkey:"showLiveJackPot2025Promo",
                        label: "PROMOTIONS@LiveJackPot2025"
                    },
                    {
                        id: '45',
                        url: '/promotions/instant-challenge-2025',
                        icon: '',
                        authkey:"showInstantChallengePromo",
                        label: "PROMOTIONS@InstantChallengePromo"
                    },
                    {
                        id: '46',
                        url: '/promotions/super-slots-challenge',
                        icon: '',
                        authkey:"showSuperSlotsChallengePromo",
                        label: "PROMOTIONS@SuperSlotsChallenge"
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONS@REFILLBONUS',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONS@FomoLossBackBonus',
                    authkey: "showFomoLossBackBonus"
                }
            ]
        },
    },
    46:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/live-casino/live-blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/live-casino/live-baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/popular-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {

                    id: 'rm1',
                    url:'/live-casino',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/slots',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
             
                    ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinko-x',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/football-x',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninja-crash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                  
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    }
                
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/aviatrix',
                    image: 'AviatriXdropdown.png',
                    label: 'GAME@AVIATRIX',
                    authkey: "showInstantThumbs"
                }
                
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/monopoly-live',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/live-casino/funky-time',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/live-casino/lightning-dice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/live-casino/DRAGONTIGER',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricket-x',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [

                {
                    id: 'rm1',
                    url:'/instant-games/mines',
                    image: 'spribe-minesdropdown.png',
                    label: 'TITLE@Mines',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/live-casino/crazy-time',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/live-casino/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                }
               
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    // {
                    //     id: '4',
                    //     url: '/betting-exchange/volleyball-betting',
                    //     icon: '',
                    //     label: 'SPORT@VolleyBall',
                    //     authkey: 'showSportVolleyball'
                    // },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    },
                   
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'DROPDOWN_EXCHANGE_Fun88.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownFun88.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@REFILL',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '15',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo"
                    },
                    {
                        id: '12',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '13',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '14',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    {
                        id: '15',
                        url: '/promotions/catch99-s2',
                        icon: '',
                        authkey:"showCatch99Bonus",
                        label: "PROMOTIONS@CATCH99"
                    },
                    // {
                    //     id: '16',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '17',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '18',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '19',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '20',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '21',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    }, 
                    {
                        id: '22',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartsoft",
                        label: "PROMOTIONS@Smartsoft"
                    },


                    {
                        id: '23',
                        url: '/promotions/evolution-casino-cup',
                        icon: '',
                        authkey:"showEvolutionCasionCup",
                        label: "PROMOTIONS@EvolutionCasionCup"
                    },




                    {
                        id: '24',
                        url: '/promotions/IPL-Betting-dhamaka-Power-Play-Bonus',
                        icon: '',
                        authkey:"shoeIPLpowerPlay",
                        label: "PROMOTIONS@IPLPOWERPLAY1"
                    },



                    {
                        id: '25',
                        url: '/promotions/indian-galaxsys-league',
                        icon: '',
                        authkey:"showIndianGalaxysLeague",
                        label: "PROMOTIONS@IndianGalaxsysLeague"
                    },
                   
                    



                        {
                        id: '26',
                        url: '/promotions/ipl-betting-dhamaka',
                        icon: '',
                        authkey:"showIplPromo",
                        label: "PROMOTIONS@iplmatka"},



                        {
                            id: '27',
                            url: '/promotions/double-cashback',
                            icon: '',
                            authkey:"showDoubleCashBack",
                            label: "PROMOTIONS@DoubleCashback"
                        },


                        {
                        id: '28',
                        url: '/promotions/aviator-sky-bound-miles',
                        icon: '',
                        authkey:"showAviatorSkyBoundMiles",
                        label: "PROMOTIONS@AviatorSkyBoundMiles"
                    },
                    {
                        id: '29',
                        url: '/promotions/wc-lossback',
                        icon: '',
                        authkey:"showWorldCupLossbackYolo",
                        label: "PROMOTIONS@WorldCupLossbackBonus"
                    },
                    {
                        id: '30',
                        url: '/promotions/wc-doubleCashback',
                        icon: '',
                        authkey:"showWorldCupDoubleCashbackBonus",
                        label: "PROMOTIONS@WorldCupDailyDepositBonus"
                    },
                    {
                        id: '31',
                        url: '/promotions/wc-evolutionCasinoCup',
                        icon: '',
                        authkey:"showWorldCupEvolutionCasinoCup",
                        label: "PROMOTIONS@WorldCupEvolutionCasinoCupBonus"
                    },
                    {
                        id: '34',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartSoftJetxCelestial",
                        label: "PROMOTIONS@SmartSoftJetxCelestial"
                    },
                    {
                        id: '35',
                        url: '/promotions/mines-legend-trophy',
                        icon: '',
                        authkey:"showMinesLegendTrophyPromo",
                        label: "PROMOTIONS@MinesLegendTrophy"
                    },
                    {
                        id: '36',
                        url: '/promotions/caribbean-premier-league',
                        icon: '',
                        authkey:"showCaribbeanLeagueBonusPromo",
                        label: "PROMOTIONS@CaribbeanLeagueBonus"
                    },
                    {
                        id: '37',
                        url: '/promotions/evolution-roulette-cup',
                        icon: '',
                        authkey:"showEvolutionRouletteCupPromo",
                        label: "PROMOTIONS@EvolutionRouletteCup"
                    },
                    {
                        id: '38',
                        url: '/promotions/aviatrix-fly-and-play',
                        icon: '',
                        authkey:"showAviatrixFlyAndPlayPromo",
                        label: "PROMOTIONS@AviatrixFlyAndPlay"
                    },
                    {
                        id: '40',
                        url: '/promotions/aviator-rain',
                        icon: '',
                        authkey:"showAviatorRainPromo",
                        label: "PROMOTIONS@AviatorRainPromo"
                    },

                    {
                        id: '48',
                        url: '/promotions/aviatrix-jet-to-cash',
                        icon: '',
                        authkey:"showAviatrixJetToCrash",
                        label: "PROMOTIONS@AviatrixJetToCrash"
                    },

                    {
                        id: '49',
                        url: '/promotions/big-bash-big-cash',
                        icon: '',
                        authkey:"showBigBashBigCashPromo",
                        label: "PROMOTIONS@BigBashBigCash"
                    },
                    {
                        id: '44',
                        url: '/promotions/the-live-jackpot',
                        icon: '',
                        authkey:"showLiveJackPot2025Promo",
                        label: "PROMOTIONS@LiveJackPot2025"
                    },
                    {
                        id: '50',
                        url: '/promotions/instant-challenge-2025',
                        icon: '',
                        authkey:"showInstantChallengePromo",
                        label: "PROMOTIONS@InstantChallengePromo"
                    },
                    {
                        id: '51',
                        url: '/promotions/super-slots-challenge',
                        icon: '',
                        authkey:"showSuperSlotsChallengePromo",
                        label: "PROMOTIONS@SuperSlotsChallenge"
                    },
                    {
                        id: '47',
                        url: '/promotions',
                        icon: '',
                        authkey:"showOnlyOnFun",
                        label: "HEADER@VIEWALL"
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@REFILL',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@CASHBACK',
                    authkey: "showCashbackBonus"
                }
            ]
        },
    },
    47:{
        casino: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/roulette',
                        icon: '',
                        label: 'TITLE@ROULETTESIDEBAR',
                        authkey: 'showRoulette'
                    },
                    {
                        id: '2',
                        url: '/live-casino/live-blackjack',
                        icon: '',
                        label: 'TITLE@BLACKJACKSIDEBAR',
                        authkey: 'showBlackjack'
                    },
                    {
                        id: '3',
                        url: '/live-casino/live-baccarat',
                        icon: '',
                        label: 'TITLE@BACCARAT',
                        authkey: 'showBaccarat'
                    },
                    {
                        id: '4',
                        url: '/live-casino/dragon-tiger',
                        icon: '',
                        label: 'TITLE@DRAGON_TIGER',
                        authkey: 'showDragontiger'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ENJOY'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm3',
                    url:'/popular-games',
                    image: 'DROPDOWN_POPULAR.png',
                    label: 'TITLE@populargames'
                },
                {

                    id: 'rm1',
                    url:'/live-casino',
                    image: 'DROPDOWN_CASINO_LIVE_CASINO.png',
                    label: 'TITLE@LIVECASINO',
                    authkey: 'showOnlineCasinoGamesLink'
                },
                {
                    id: 'rm2',
                    url:'/slots',
                    image: 'DROPDOWN_SLOT_BINGO.png',
                    label: 'TITLE@SLOTBINGO'
                }
            
             
                    ]
        },
        instant_games: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/instant-games/plinko-x',
                        icon: '',
                        label: "TITLE@Plinko",
                        authkey: "showInstantThumbs"
                    },
                    // {
                    //     id: '2',
                    //     url: '/instant-games/football-x',
                    //     icon: '',
                    //     label: 'TITLE@Footballx',
                    //     authkey: "showInstantThumbs"
                    // },
                    // {
                    //     id: '3',
                    //     url: '/instant-games/balloon',
                    //     icon: '',
                    //     label: 'TITLE@Baloon',
                    //     authkey: "showInstantThumbs"
                    // },
                    {
                        id: '4',
                        url: '/instant-games/mines',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@Mines"
                    },
                    {
                        id: '5',
                        url: '/instant-games/ninja-crash',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "TITLE@ninjacrush"
                    },
                  
                    {
                        id: '7',
                        url: '/instant-games',
                        icon: '',
                        authkey:"showInstantThumbs",
                        label: "HEADER@VIEWALL"
                    }
                
                
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/instant-games/aviator',
                    image: 'aviator-instant-cashsite-dropdown.png',
                    label: 'TITLE@AVIATOR',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm2',
                    url: '/instant-games/jetx',
                    image: 'jetx-instant-cashsite-drowpdown.png',
                    label: 'TITLE@JetX',
                    authkey: "showInstantThumbs"
                },
                {
                    id: 'rm3',
                    url: '/instant-games/aviatrix',
                    image: 'AviatriXdropdown.png',
                    label: 'GAME@AVIATRIX',
                    authkey: "showInstantThumbs"
                }
                
            ]
        },
        hotgames: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/live-casino/monopoly-live',
                        icon: '',
                        label: 'TITLE@MonopolyLive',
                        authkey: 'showTeenpatti'
                    },
                    {
                        id: '2',
                        url: '/live-casino/funky-time',
                        icon: '',
                        label: 'TITLE@FunkyTimes',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '3',
                        url: '/live-casino/lightning-dice',
                        icon: '',
                        label: 'TITLE@LightningDice',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '4',
                        url: '/live-casino/DRAGONTIGER',
                        icon: '',
                        label: 'TITLE@DragonTiger',
                        authkey: 'showTeenpatti2020'
                    },
                    {
                        id: '5',
                        url: '/instant-games/cricket-x',
                        icon: '',
                        label: 'TITLE@CricketX',
                        authkey: 'showTeenpatti2020'
                    },
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@PLAYANDNEVER'
                    },
                ]
            },
            rightMenu: [

                {
                    id: 'rm1',
                    url:'/instant-games/mines',
                    image: 'spribe-minesdropdown.png',
                    label: 'TITLE@Mines',
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm2',
                    url:'/live-casino/crazy-time',
                    image: 'DROPDOWN_CRAZY_TIME.png',
                    label: "TITLE@crazytime",
                    authkey: 'showTeenpattiLink'
                },
                {
                    id: 'rm3',
                    url:'/live-casino/lightning-roulette',
                    image: 'DROPDOWN_LIGHTNING_ROULETTE.png',
                    label: 'TITLE@LIGHTINGROULETTE',
                    authkey: 'showTeenpattiLink'
                }
               
            ]
        },
        sports: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/betting-exchange/online-cricket-betting',
                        icon: '',
                        label: 'SPORT@CRICKET',
                        authkey: 'showSportCricket'
                    },
                    {
                        id: '2',
                        url: '/betting-exchange/football-betting',
                        icon: '',
                        label: 'SPORT@FOOTBALL',
                        authkey: 'showSportFootball'
                    },
                    {
                        id: '3',
                        url: '/betting-exchange/tennis-betting',
                        icon: '',
                        label: 'SPORT@TENNIS',
                        authkey: 'showSportTennis'
                    },
                    // {
                    //     id: '4',
                    //     url: '/betting-exchange/volleyball-betting',
                    //     icon: '',
                    //     label: 'SPORT@VolleyBall',
                    //     authkey: 'showSportVolleyball'
                    // },
                    {
                        id: '5',
                        url: '/virtualSports/vci',
                        icon: '',
                        label: 'TITLE@VIRTUALSPORTS',
                        authkey: 'showVirtualSportsvfec'
                    },
                    {
                        id: '6',
                        url: '/sports/tournaments/',
                        icon: '',
                        label: 'TITLE@Tournaments',
                        authkey: 'showTournaments'
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@GOONTURN'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url:'/betting-exchange',
                    image: 'DROPDOWN_EXCHANGE_Fun88.png',
                    label: 'TITLE@EXCHANGE',
                    authkey: 'showExchangeLink'
                },
                {
                    id: 'rm2',
                    url:'/premium-sportsbook',
                    image: 'premiumSportsbookDropdownFun88.png',
                    label: 'TITLE@PREMIUM_SPORTSBOOK',
                    authkey: 'showPremiumSportsLink'
                },
                {
                    id: 'rm3',
                    url:'/sportsbook',
                    image: 'DROPDOWN_SPORTSBOOK_NEW.png',
                    label: 'MENU@SPORTBOOK',
                    authkey: 'showSportBookLink'
                },
                // {
                //     id: 'rm4',
                //     url:'/virtualSports/vci',
                //     image: 'virtualSportsDesktopDropdown.png',
                //     label: 'GAME@VIRTUALSPORTS',
                //     authkey: 'showVirtualSportsvfel'
                // },
            ]
        },
        promotions: {
            leftMenu: {
                links: [
                    {
                        id: '1',
                        url: '/promotions/welcome-bonus',
                        icon: '',
                        label: "PROMOTIONSTITLE@WELCOME"
                    },
                    {
                        id: '2',
                        url: '/promotions/refill-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@REFILL',
                        authkey: "showRefillBonus"
                    },
                    {
                        id: '3',
                        url: '/promotions/cashback-bonus',
                        icon: '',
                        label: 'PROMOTIONSTITLE@CASHBACK',
                        authkey: "showCashbackBonus"
                    },
                    {
                        id: '4',
                        url: '/promotions/referral-bonus',
                        icon: '',
                        authkey:"showReferralBonus",
                        label: "ProBonus@Referral"
                    },   
                    {
                        id: '5',
                        url: '/promotions/big-bash',
                        icon: '',
                        authkey:"showBBLBonus",
                        label: "PROMOTIONS@BBLBonus"
                    },
                    {
                        id: '6',
                        url: '/promotions/arjith_promo',
                        icon: '',
                        authkey:"showSingWithYolo",
                        label: "PROMOTIONS@singYolo"
                    },
                    {
                        id: '7',
                        url: '/promotions/jetx-tournament',
                        icon: '',
                        authkey:"jetxpromo",                                 
                        label:"jetx"
                    },
                    {
                        id: '8',
                        url: '/promotions/crazyCrash',
                        icon: '',
                        authkey:"showCrazyCrashPromo",
                        label:"PROMOTIONS@crazyCrashPromo"
                    },
                    {   id: '9',
                        url: '/promotions/aviator-bonus',
                        icon: '',
                        authkey:"showAviatorBonus",
                        label:"PROMOTIONS@AviatorBonus"
                    },   
                    {
                        id: '10',
                        url: '/promotions/evolution-first-bet',
                        icon: '',
                        authkey:"showEvolutionpromo",
                        label: "PROMOTIONS@firstperson"
                    },
                    {
                        id: '11',
                        url: '/promotions/funkytime',
                        icon: '',
                        authkey:"showFunkytimePromo",
                        label: "PROMOTIONS@funkytime"
                    },
                    {
                        id: '15',
                        url: '/promotions/evo-baccarat',
                        icon: '',
                        authkey:"showEvoCasinoCupPromo",
                        label: "PROMOTIONS@EvoCasinoCupPromo"
                    },
                    {
                        id: '12',
                        url: '/promotions/worldcup-cashback-bonanza',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "PROMOTIONS@WCCBONAZA"
                    },
                    {
                        id: '13',
                        url: '/promotions/platinum-rush',
                        icon: '',
                        authkey:"showPlatinumRush",
                        label: "PROMOTIONS@Platinumrush"
                    },
                    {
                        id: '14',
                        url: '/promotions/worldcup-betboost',
                        icon: '',
                        authkey:"showWCBonusPromo",
                        label: "TITLE@wcbetboost"
                    },
                    {
                        id: '15',
                        url: '/promotions/catch99-s2',
                        icon: '',
                        authkey:"showCatch99Bonus",
                        label: "PROMOTIONS@CATCH99"
                    },
                    // {
                    //     id: '16',
                    //     url: '/promotions/evolution-crazy-pachinko',
                    //     icon: '',
                    //     authkey:"showCrazyPachinkoPromo",
                    //     label: "TITLE@EvoCrazyPachinko"
                    // },
                    {
                        id: '17',
                        url: '/promotions/jetx-celestial-rush',
                        icon: '',
                        authkey:"showJetXCelestialRush",
                        label: "PROMOTIONS@JetxCelestial"
                    },
                    {
                        id: '18',
                        url: '/promotions/aviatrix-double-dhamaka',
                        icon: '',
                        authkey:"showAviatrixPromo",
                        label: "PROMOTIONS@AviatrixDoubleDhamaka"
                    },
                    {
                        id: '19',
                        url: '/promotions/cricket-cashback-marathon',
                        icon: '',
                        authkey:"showcircketCashbackMarathon",
                        label: "PROMOTIONS@CricketCashbackMarathon"
                    },
                    {
                        id: '20',
                        url: '/promotions/crazytime-epic-bonanza',
                        icon: '',
                        authkey:"showCrazyTimePromo",
                        label: "PROMOTIONS@CrazyTimeEpicBonanza"
                    },
                    {
                        id: '21',
                        url: '/promotions/aviator-high-flyer',
                        icon: '',
                        authkey:"showAviatorHighFlyerPromo",
                        label: "PROMOTIONS@AviatorHighFlyer"
                    }, 
                    {
                        id: '22',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartsoft",
                        label: "PROMOTIONS@Smartsoft"
                    },


                    {
                        id: '23',
                        url: '/promotions/evolution-casino-cup',
                        icon: '',
                        authkey:"showEvolutionCasionCup",
                        label: "PROMOTIONS@EvolutionCasionCup"
                    },




                    {
                        id: '24',
                        url: '/promotions/IPL-Betting-dhamaka-Power-Play-Bonus',
                        icon: '',
                        authkey:"shoeIPLpowerPlay",
                        label: "PROMOTIONS@IPLPOWERPLAY1"
                    },



                    {
                        id: '25',
                        url: '/promotions/indian-galaxsys-league',
                        icon: '',
                        authkey:"showIndianGalaxysLeague",
                        label: "PROMOTIONS@IndianGalaxsysLeague"
                    },
                   
                    



                        {
                        id: '26',
                        url: '/promotions/ipl-betting-dhamaka',
                        icon: '',
                        authkey:"showIplPromo",
                        label: "PROMOTIONS@iplmatka"},



                        {
                            id: '27',
                            url: '/promotions/double-cashback',
                            icon: '',
                            authkey:"showDoubleCashBack",
                            label: "PROMOTIONS@DoubleCashback"
                        },


                        {
                        id: '28',
                        url: '/promotions/aviator-sky-bound-miles',
                        icon: '',
                        authkey:"showAviatorSkyBoundMiles",
                        label: "PROMOTIONS@AviatorSkyBoundMiles"
                    },
                    {
                        id: '29',
                        url: '/promotions/wc-lossback',
                        icon: '',
                        authkey:"showWorldCupLossbackYolo",
                        label: "PROMOTIONS@WorldCupLossbackBonus"
                    },
                    {
                        id: '30',
                        url: '/promotions/wc-doubleCashback',
                        icon: '',
                        authkey:"showWorldCupDoubleCashbackBonus",
                        label: "PROMOTIONS@WorldCupDailyDepositBonus"
                    },
                    {
                        id: '31',
                        url: '/promotions/wc-evolutionCasinoCup',
                        icon: '',
                        authkey:"showWorldCupEvolutionCasinoCup",
                        label: "PROMOTIONS@WorldCupEvolutionCasinoCupBonus"
                    },
                    {
                        id: '34',
                        url: '/promotions/smartsoft-spin-and-win',
                        icon: '',
                        authkey:"showSmartSoftJetxCelestial",
                        label: "PROMOTIONS@SmartSoftJetxCelestial"
                    },
                    {
                        id: '35',
                        url: '/promotions/mines-legend-trophy',
                        icon: '',
                        authkey:"showMinesLegendTrophyPromo",
                        label: "PROMOTIONS@MinesLegendTrophy"
                    },
                    {
                        id: '36',
                        url: '/promotions/caribbean-premier-league',
                        icon: '',
                        authkey:"showCaribbeanLeagueBonusPromo",
                        label: "PROMOTIONS@CaribbeanLeagueBonus"
                    },
                    {
                        id: '37',
                        url: '/promotions/evolution-roulette-cup',
                        icon: '',
                        authkey:"showEvolutionRouletteCupPromo",
                        label: "PROMOTIONS@EvolutionRouletteCup"
                    },
                    {
                        id: '38',
                        url: '/promotions/aviatrix-fly-and-play',
                        icon: '',
                        authkey:"showAviatrixFlyAndPlayPromo",
                        label: "PROMOTIONS@AviatrixFlyAndPlay"
                    },
                    {
                        id: '40',
                        url: '/promotions/aviator-rain',
                        icon: '',
                        authkey:"showAviatorRainPromo",
                        label: "PROMOTIONS@AviatorRainPromo"
                    },
                    {
                        id: '41',
                        url: '/promotions/aviatrix-jet-to-cash',
                        icon: '',
                        authkey:"showAviatrixJetToCrash",
                        label: "PROMOTIONS@AviatrixJetToCrash"
                    },
                
                    {
                        id: '42',
                        url: '/promotions/big-bash-big-cash',
                        icon: '',
                        authkey:"showBigBashBigCashPromo",
                        label: "PROMOTIONS@BigBashBigCash"
                    },
                    {
                        id: '50',
                        url: '/promotions/instant-challenge-2025',
                        icon: '',
                        authkey:"showInstantChallengePromo",
                        label: "PROMOTIONS@InstantChallengePromo"
                    },
                    {
                        id: '51',
                        url: '/promotions/super-slots-challenge',
                        icon: '',
                        authkey:"showSuperSlotsChallengePromo",
                        label: "PROMOTIONS@SuperSlotsChallenge"
                    },
                    {
                        id: '43',
                        url: '/promotions/the-live-jackpot',
                        icon: '',
                        authkey:"showLiveJackPot2025Promo",
                        label: "PROMOTIONS@LiveJackPot2025"
                    },
                    {
                        id: '44',
                        url: '/promotions',
                        icon: '',
                        authkey:"showOnlyOnFun",
                        label: "HEADER@VIEWALL"
                    }
                ],
                footer: [
                    {
                        id: '1',
                        url: '',
                        icon: '',
                        label: 'MENU@ExploreALL'
                    },
                ]
            },
            rightMenu: [
                {
                    id: 'rm1',
                    url: '/promotions/welcome-bonus',
                    image: 'DROPDOWN_WELCOME_BONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@WELCOME',
                    authkey: "showWelcomeBonus"
                },
                {
                    id: 'rm2',
                    url: '/promotions/refill-bonus',
                    image: 'DROPDOWN_REFILLBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@REFILL',
                    authkey: "showRefillBonus"
                },
                {
                    id: 'rm3',
                    url: '/promotions/cashback-bonus',
                    image: 'DROPDOWN_CASHBACKBONUS_NEW.png',
                    label: 'PROMOTIONSTITLE@CASHBACK',
                    authkey: "showCashbackBonus"
                }
            ]
        },
    }
}
